/* You can add global styles to this file, and also import other style files */
.cesium-infoBox {
  top: 0px;
  left: 0;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
  height: 430px!important;
  overflow: hidden;
  background: rgba(38, 38, 38, 0.95);
  color: white;
  width: 330px!important;
  z-index: 999;
  /*transition: translate 0s;*/
}


.cesium-infoBox-title {
  cursor: move;
  border-top-right-radius: 7px;

  background: rgba(38, 38, 38, 1);
}

.cesium-infoBox-description {
  overflow: hidden;
}

.cesium-infoBox-title {
  border-top-right-radius: 7px;
}

body{
  height:100vh;
}

/* scrollbar width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* scrollbar Track */
::-webkit-scrollbar-track {
  background: rgb(0,0,0,0);
}

/* scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #48b;
    border: 1px solid #aef;
    border-radius: 3px;
}

.cesium-infoBox-iframe {
  display: flex!important;
  padding: 0 5px 5px 5px;
  background: #303336;
  height: 400px!important;
  width:100%!important;
}

.spinsies {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #aef transparent #aef transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
    100% {
    transform: rotate(360deg);
    }
}

.loader-container {
  width: 100%;
  height: 400px;
  display:flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #303336;
  z-index: 1;
}

/*::-webkit-resizer {*/
/*  border: solid #7C7C7C;*/
/*  border-width: 0 3px 3px 0;*/
/*  border-radius: 3px;*/

/*}*/

